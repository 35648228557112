import * as React from 'react'

import {
  ILocation,
  IVirtualEvent,
  ListItemProps,
} from '@watermarkchurch/location-map'
import {
  calculateDistanceText,
  renderAddress,
  renderMeetingTime,
  renderMeetingTZ,
} from '@watermarkchurch/location-map'

export const ListItem = (props: ListItemProps) => {
  const { hit } = props

  if (hit.isVirtual) {
    return <VirtualListItem {...props} hit={hit} />
  }

  return <InPersonListItem {...props} hit={hit} />
}

interface IInPersonListItemProps extends ListItemProps {
  hit: ILocation
}

const InPersonListItem: React.FunctionComponent<IInPersonListItemProps> = (props) => {
  const {hit, currentRefinement} = props
  return (
    <div className="churches-list__item__content">
      <h4 className="churches-list__item__content__title">
        { hit.title }
      </h4>

      <div className="churches-list__item__content__details">
        <span className="churches-list__item__content__text">
          {hit.address.city}, {hit.address.state}
        </span>
        &nbsp;
        {currentRefinement.userLocation && <>
          <span className="churches-list__item__content__distance">
            {calculateDistanceText(props)}
          </span>
          &nbsp;
        </>}
        <span className="churches-list__item__content__datetime">
          { renderMeetingTime(hit, props.translate) }
        </span>
        {hit.trainingLocation && <>
          &nbsp;
          <TrainingLocationStar />
        </>}
      </div>

    </div>
  )
}

interface IVirtualListItemProps extends ListItemProps {
  hit: IVirtualEvent
}

const VirtualListItem: React.FunctionComponent<IVirtualListItemProps> = ({hit, translate}) => {
  const genderKey = hit.genders.length > 1 ? 'all' : hit.genders[0]

  const meetingTz = renderMeetingTZ(hit.meetingTimeZone, hit.next_occurrence && hit.next_occurrence.start_time_ts)

  return (
    <div className="churches-list__item__content">
      <h4 className="churches-list__item__content__title">
        { renderMeetingTime(hit, translate) } { meetingTz }
      </h4>

      <div>
        {hit.address && <span className="churches-list__item__content__text">
          { renderAddress(hit.address) }
        </span>}

        {hit.genders.length > 0 && <span className="churches-list__item__content__datetime">
          &nbsp;{ translate(['locationMap', 'meetingGender', genderKey]) }
        </span>}

        <span className="churches-list__item__content__account-name">
          {hit.title}
        </span>

        {hit.trainingLocation && <>
          &nbsp;
          <TrainingLocationStar />
        </>}
      </div>

    </div>
  )
}

/* tslint:disable */
const TrainingLocationStar = () =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#74891A"/>
    <path d="M11.9984 4L10.0326 8.88901L5 9.34155L8.81731 12.818L7.67162 18L11.9984 15.2534L16.3252 18L15.182 12.8102L18.9968 9.33109L13.9642 8.87855L11.9984 4Z" fill="white"/>
  </svg>
